import { useIsInViewport } from '@kaliber/use-is-in-viewport'
import { useMediaQuery } from '/machinery/useCachingMediaQuery'

import { ContainerSm, ContainerMd, ContainerLg } from '/features/buildingBlocks/Container'

import mediaStyles from '/cssGlobal/media.css'
import styles from './LoopingVideo.css'
import { PhotoIconOverlay } from '/features/buildingBlocks/PhotoIconOverlay'
import { Caption } from '/features/buildingBlocks/Caption'

/**
 * @param {{
 *    description: string,
 *    src: string,
 *    orientation: 'square' | 'portrait' | 'landscape'
 * }} _
 */
export function LoopingVideoAll({ description, src, orientation }) {
  if (orientation === 'square') return <LoopingVideoSquare {...{ description, src }} />
  if (orientation === 'portrait') return <LoopingVideoPortrait {...{ description, src }} />
  if (orientation === 'landscape') return <LoopingVideoLandscape {...{ description, src }} />
  return <LoopingVideoSquare {...{ description, src }} />
}

function LoopingVideoPortrait({ description, src }) {
  return <LoopingVideoBase ContainerComponent={ContainerSm} className={styles.componentPortrait} {...{ description, src }} />
}

function LoopingVideoSquare({ description, src }) {
  return <LoopingVideoBase ContainerComponent={ContainerMd} className={styles.componentSquare} {...{ description, src }} />
}

function LoopingVideoLandscape({ description, src }) {
  return <LoopingVideoBase ContainerComponent={ContainerLg} className={styles.componentLandscape} {...{ description, src }} />
}

function LoopingVideoBase({ description, src, ContainerComponent, className }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  const { ref: isInViewportRef, isInViewport } = useIsInViewport()
  const videoRef = React.useRef(null)

  const ContainerElement = isViewportMd ? ContainerComponent : React.Fragment

  React.useEffect(
    () => {
      if (!isInViewport) videoRef.current?.pause()
      else videoRef.current?.play()
    },
    [isInViewport]
  )

  return (
    <ContainerElement>
      <div ref={isInViewportRef} className={cx(styles.componentBase, className)}>
        <video
          ref={videoRef}
          loop
          muted
          autoPlay
          playsInline
          disablePictureInPicture
          controlsList="nodownload"
          className={styles.video}
          {...{ src }}
        />
        <div className={styles.captionContainer}>
          {description && <PhotoIconOverlay
            layoutClassName={styles.captionLayout}
            renderContent={() => <Caption caption={description} />}
          />}
        </div>
      </div>
    </ContainerElement>
  )
}
