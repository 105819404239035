import { animated } from '@react-spring/web'

import { Image, ImageCover } from '/features/buildingBlocks/Image'
import { BreakpointImageCover } from '/features/buildingBlocks/BreakpointImageCover'

import styles from './CardImage.css'

export function CardImageNonCover({ image, animation = undefined, layoutClassName = undefined }) {
  return (
    <CardImageBase className={layoutClassName} {...{ image, animation }}>
      {image && (
        <Image imgProps={{ draggable: false, loading: 'lazy' }} layoutClassName={styles.imageLayout} {...{ image }} />
      )}
    </CardImageBase>
  )
}

export function CardImage({ image, animation = undefined, aspectRatio = undefined, sizes = undefined, layoutClassName = undefined }) {
  return (
    <CardImageBase className={layoutClassName} {...{ image, animation, aspectRatio, sizes }}>
      {image && (
        <ImageCover
          sizes={sizes || '100vw'}
          imgProps={{ draggable: false, loading: 'lazy' }}
          layoutClassName={styles.imageLayout}
          {...{ image, aspectRatio }}
        />
      )}
    </CardImageBase>
  )
}

export function CardImageWithoutShadow({ image, animation = undefined, sizes = undefined, layoutClassName = undefined }) {
  return (
    <CardImageBase className={cx(styles.componentWithoutShadow, layoutClassName)} {...{ image, animation, sizes }}>
      {image && (
        <BreakpointImageCover
          sizes={sizes || '100vw'}
          aspectRatioMobile={9 / 16}
          aspectRatioDesktop={4 / 3}
          imgProps={{ draggable: false, loading: 'lazy' }}
          layoutClassName={styles.imageLayout}
          {...{ image }}
        />
      )}
    </CardImageBase>
  )
}

function CardImageBase({ animation, children, className }) {
  return (
    <div className={cx(styles.componentBase, className)}>
      <animated.div style={animation} className={styles.imageAnimation}>
        {children}
      </animated.div>
    </div>
  )
}
