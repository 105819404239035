import { animated } from '@react-spring/web'
import { useCardAnimations } from '/features/regionArticles/cards/useCardAnimations'
import { useItemUrl } from '/features/regionArticles/useItemUrl'
import { useDrag } from '@use-gesture/react'

import { CardWithGradientBackgroundGreen } from '/features/regionArticles/cards/Card'
import { CardImageWithoutShadow } from '/features/regionArticles/cards/CardImage'
import { HeadingGroupSnackables } from '/features/buildingBlocks/HeadingGroup'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './CardMemberOffer.css'

import arrowRight from '/images/icons/arrow-right.raw.svg'

export function CardMemberOffer({ item, layoutClassName }) {
  const { content, metadata } = item
  const { hero, articles } = content
  const { rubric } = metadata

  const url = useItemUrl(articles[0])
  const bindNavigateOnTap = useNavigateOnTap({ url })
  const animations = useCardAnimations()

  return (
    <CardWithGradientBackgroundGreen
      contentContainerClassName={styles.component}
      gestureBindings={bindNavigateOnTap()}
      {...{ layoutClassName }}
    >
      <CardMemberOfferContentBase
        title={hero.title}
        rubric={rubric.name}
        className={styles.contentLayout}
        {...{ articles, animations }}
      />

      {url && (
        <NavigationButton
          animation={animations.navigationButton}
          layoutClassName={styles.navigationButtonLayout}
          {...{ url }}
        />
      )}
    </CardWithGradientBackgroundGreen>
  )
}

function NavigationButton({ url, animation, layoutClassName = undefined }) {
  // TODO: Erik - this button has no effect because of the useNavigateOnTap in the parent. Buttons and things like that should prevent propagation
  return (
    <animated.a href={url} draggable='false' data-x='link-to-detail' className={cx(styles.componentNavigationButton, layoutClassName)} style={animation}>
      <Icon icon={arrowRight} />
    </animated.a>
  )
}

export function CardMemberOfferContentIntro({ title, rubric, articles, animations = undefined, layoutClassName = undefined }) {
  return <CardMemberOfferContentBase className={cx(styles.componentContentIntro, layoutClassName)} {...{ title, rubric, articles, animations }} />
}

function CardMemberOfferContentBase({ title, rubric, articles, animations, className }) {
  return (
    <div className={cx(styles.componentContentBase, className)}>
      <Grid animation={animations?.imageScale} layoutClassName={styles.gridLayout} {...{ articles }} />
      <Header animation={animations?.headingGroup} layoutClassName={styles.headerLayout} {...{ title, rubric }} />
    </div>
  )
}

function Grid({ articles, animation, layoutClassName }) {
  return (
    <div
      className={cx(
        styles.componentGrid,
        styles[`gridLayoutWith${articles?.length || 0}Items`],
        layoutClassName
      )}
      style={{ '--item-count': articles?.length || 1 }}
    >
      {articles?.map((article, i) => (
        <CardImageWithoutShadow key={i} image={article?.content?.hero?.image} {...{ animation }} />
      ))}
    </div>
  )
}

function Header({ title, rubric, animation, layoutClassName }) {
  return (
    <div className={cx(styles.componentHeader, layoutClassName)}>
      <div className={styles.headerContainer}>
        <HeadingGroupSnackables subtitle={rubric} {...{ title, animation }} />
      </div>
    </div>
  )
}

function useNavigateOnTap({ url }) {
  return useDrag(({ tap }) => { if (tap) window.location.href = url }, {})
}
