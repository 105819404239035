import { useTranslate } from '/machinery/I18n'
import { useReportError } from '/machinery/ReportError'
import { useFirebaseApp } from '/machinery/firebase'
import { useForm, useFormField } from '@kaliber/forms'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getAuth, signInAnonymously } from 'firebase/auth'
import { get, getDatabase, ref, serverTimestamp, set } from 'firebase/database'
import { animated, useSprings } from '@react-spring/web'
import { required } from '@kaliber/forms/validation'
import { reportClientError } from '/machinery/reportClientError'

import { HeadingXs } from '/features/buildingBlocks/Heading'
import { Checkbox } from '/features/buildingBlocks/Checkbox'
import { ButtonIconWithLabel } from '/features/buildingBlocks/Button'

import styles from './Poll.css'

import chrevronRight from '/images/icons/chevron-right.raw.svg'

export function Poll({ issue, poll, layoutClassName = undefined }) {
  const client = useQueryClient()
  const { __ } = useTranslate()

  const { data: hasSuccesfullySubmitted, isLoading } = useVoteStatus({ issue })
  const { question, answers, closed } = poll || {}

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <HeadingXs h={4}>{question}</HeadingXs>
      <p className={styles.body}>{__`poll-pick-option`}</p>

      {isLoading
        ? <Skeleton {...{ answers }} />
        : (closed || hasSuccesfullySubmitted)
          ? <PollResults {...{ issue, answers }} />
          : (
            <PollForm
              onSuccess={() => client.invalidateQueries({ queryKey: ['poll', issue] })}
              {...{ issue, question, answers }}
            />
          )
      }
    </div>
  )
}

function Skeleton({ answers }) {
  return (
    <div className={styles.componentSkeleton}>
      {answers?.map((_, i) => (
        <div key={i} className={styles.bone} />
      ))}
    </div>
  )
}

function ListItem({ label, checked, onChange }) {
  return (
    <li className={styles.componentListItem}>
      <Checkbox
        name='subject'
        dataX='click-to-vote-for-subject'
        layoutClassName={styles.checkboxLayout}
        {...{ checked, label, onChange }}
      />
    </li>
  )
}

function PollResults({ issue, answers }) {
  const { animations, voteCount, voteObject } = usePollResults({ issue, answers })

  return (
    <div className={styles.componentResults}>
      {Boolean(voteCount && voteObject) && answers.map((label, i) => {
        const value = animations[i].value
        const id = `q${i}-progress`

        return (
          <div key={i} className={styles.result} aria-live="polite">
            <label htmlFor={id} className={styles.resultLabel}>{label}</label>

            <div className={styles.resultRow}>
              <p>{getFiniteVotes({ total: voteCount, votes: voteObject[label] })}%</p>
              <Progress layoutClassName={styles.progressLayout} {...{ id, value }} />
            </div>
          </div>
        )
      }
      )}
    </div>
  )
}

function Progress({ value, id, layoutClassName }) {
  return (
    <animated.progress
      max={100}
      className={cx(styles.componentProgress, layoutClassName)}
      {...{ id, value }}
    />
  )
}

// Prevent 0/0=Infinity issues
function getFiniteVotes({ total, votes }) {
  return Number.isFinite(votes / total)
    ? roundToTwoDecimals((votes / total) * 100)
    : 0
}

function roundToTwoDecimals(x) {
  return parseFloat((x || 0).toFixed(2))
}

function usePollResults({ issue, answers }) {
  const firebaseApp = useFirebaseApp('poll-form')

  const { data: { voteCount, voteObject } } = useQuery({
    enabled: Boolean(firebaseApp),
    queryKey: ['results', issue],
    queryFn: () => getPollResults(firebaseApp, issue),
    initialData: { voteCount: 0, voteObject: {} }
  })

  const [animations] = useSprings(answers?.length, i => ({
    to: { value: getFiniteVotes({ total: voteCount, votes: voteObject[answers[i]] }) },
    from: { value: 0 },
    delay: i * 100
  }), [voteCount, voteObject])

  return { animations, voteCount, voteObject }
}

function PollForm({ issue, question, answers, onSuccess }) {
  const { __ } = useTranslate()
  const reportError = useReportError()
  const firebaseApp = useFirebaseApp('poll-form')

  const { form, submit } = useForm({
    initialValues: { answer: null },
    fields: { answer: required },
    onSubmit: handleSubmit,
  })

  const { fields } = form

  const { mutate } = useMutation({
    mutationFn: storeFormValuesInFirebase,
    onError: reportError,
    onSuccess
  })

  const { state: { value: answerValue }, eventHandlers } = useFormField(fields.answer)

  return (
    <form className={styles.componentForm} onSubmit={submit}>
      <ul className={styles.list}>
        {answers?.map((label, i) => (
          <ListItem
            key={i}
            checked={answerValue === label}
            onChange={() => eventHandlers.onChange(label)}
            {...{ label }}
          />
        ))}
      </ul>

      <ButtonIconWithLabel
        label={__`vote`}
        icon={chrevronRight}
        onClick={submit}
        dataX='click-to-vote'
        layoutClassName={styles.buttonLayout}
      />
    </form>
  )

  function handleSubmit(snapshot) {
    mutate(snapshot.value)
  }

  async function storeFormValuesInFirebase({ answer }) {
    const { user: { uid } } = await signInAnonymously(getAuth(firebaseApp))

    return set(ref(getDatabase(firebaseApp), `poll/entries/${uid + issue}`), {
      formSubmitDate: serverTimestamp(),
      url: window.location.href,
      questionAndAnswer: {
        question,
        answer
      },
      issue,
      uid,
    })
  }
}

function useVoteStatus({ issue }) {
  const firebaseApp = useFirebaseApp('poll-form')

  return useQuery({
    enabled: Boolean(firebaseApp),
    queryKey: ['poll', issue],
    queryFn: () => getVoteStatus(firebaseApp, issue),
  })
}

async function getVoteStatus(fbApp, issue) {
  try {
    const { user: { uid } } = await signInAnonymously(getAuth(fbApp))
    const reference = await get(ref(getDatabase(fbApp), `poll/entries/${uid + issue}`))

    return reference.exists()
  } catch (e) {
    reportClientError(e)
    return false
  }
}

async function getPollResults(firebaseApp, issue) {
  try {
    const reference = ref(getDatabase(firebaseApp), `poll/entries`)
    const votesEntriesArray = Object.entries((await get(reference)).val())
    const currentIssueEntries = votesEntriesArray.filter(([, v]) => v?.issue === issue)

    const voteCount = currentIssueEntries.length
    const voteObject = currentIssueEntries
      .reduce((result, [_, { questionAndAnswer: x }]) =>
        ({ ...result, [x.answer]: (result[x.answer] || 0) + 1 })
      , {})

    return { voteCount, voteObject }
  } catch (e) {
    reportClientError(e)
    return  { voteCount: 0, voteObject: {} }
  }
}
